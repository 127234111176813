import React from "react";
import { Link, graphql } from "gatsby";
import { rhythm } from "../utils/typography";

export default function Feed({ feed }) {
  return feed.map(({ node }) => (
    <article key={node.fields.slug}>
      <header>
        <h3
          style={{
            marginBottom: rhythm(1 / 4),
          }}
        >
          <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
            {node.frontmatter.title}
          </Link>
        </h3>
        <time dateTime={node.frontmatter.fullDate} style={{ fontSize: "80%" }}>
          {node.frontmatter.date}
        </time>
      </header>
      <section>
        <p
          dangerouslySetInnerHTML={{
            __html: node.frontmatter.description || node.excerpt,
          }}
        />
      </section>
    </article>
  ));
}

export const feedItemFragment = graphql`
  fragment Feed_item on MarkdownRemark {
    id
    excerpt
    fields {
      slug
    }
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      fullDate: date
      title
      description
    }
  }
`;
