import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Feed from "../../components/feed";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

export default class TeamTemplate extends React.Component {
  render() {
    const team = this.props.data.teamsJson;
    const featuredImage = getImage(team.images && team.images.length && team.images[0]);
    const episodes = this.props.data.allMarkdownRemark.edges;
    return (
      <Layout>
        <SEO
          title={`${team.name} Podcast Episodes | Larry Knows Sports`}
          description={`All podcast episodes of Larry Knows Sports about the ${team.name}`}
          featuredImage={featuredImage}
        />
        <h1>{team.name} Podcast Episodes</h1>
        <Feed feed={episodes} />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query ($fields__slug: String!) {
    teamsJson(fields: { slug: { eq: $fields__slug } }) {
      name
      league
      sport
      fields {
        slug
      }
      images {
        childImageSharp {
          gatsbyImageData(width: 800)
        }
      }
    }

    allMarkdownRemark(
      filter: { frontmatter: { teams: { elemMatch: { fields: { slug: { eq: $fields__slug } } } } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          ...Feed_item
        }
      }
    }
  }
`;
